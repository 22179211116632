<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      class="mb-0"
    >
      <b-row class="py-1">
        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :to="{name: 'sub-accounts-create'}"
              >
                <feather-icon icon="UserPlusIcon" />
                {{ $t('New Sub Account') }}
              </b-button>
            </div>
            <div>
              <VueGoodTableSwitchCompactMode />
            </div>
          </div>

        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="table"
        >
          <VueGoodTableRemote
            ref="tableDataMember"
            :total-records="totalRecords"
            :rows="rows"
            :columns="columns"
            :is-line-number="true"
            :is-loading="$wait.is('table-loading-data')"
            :pagination-enabled="totalRecords >= 100"
            @onLoadData="onLoadData"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusColorBoolean(props.row.active)">
                  {{ statusActiveBoolean(props.row.active) }}
                </b-badge>
              </span>
              <div
                v-else-if="props.column.field === 'username'"
                class="table__row"
              >
                <span
                  class="link--text-latest"
                >
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </div>
              <span v-else-if="props.column.field === 'last_login'">
                {{ DatetimeFormat(props.row.last_login) }}
              </span>
              <div
                v-else-if="props.column.field === 'editSetting'"
                class="d-flex justify-content-center"
              >
                <b-button
                  variant="primary"
                  class="btn-icon rounded-circle"
                  size="sm"
                  :to="{
                    name: 'sub-accounts-edit',
                    params: {
                      id: props.row._id
                    }
                  }"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </VueGoodTableRemote>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import store from '@/store'
import { VueGoodTable } from 'vue-good-table-custom'
import VueGoodTableRemote from '@/components/VueGoodTableRemote'
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'
import {
  statusColorBoolean,
  statusActiveBoolean,
} from '@/utils/statusBoolean.util'

import { DatetimeFormat } from '@/utils/date-format'

export default {
  components: {
    VueGoodTable,
    VueGoodTableRemote,
    VueGoodTableSwitchCompactMode,
    vSelect,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t('Username'),
          field: 'username',
          width: '130px',
        },
        {
          label: this.$t('Contact'),
          field: 'contact',
          sortable: false,
          width: '180px',
        },
        {
          label: this.$t('Level'),
          field: 'type',
          width: '80px',
        },
        {
          label: this.$t('Status'),
          field: 'status',
          sortable: false,
          width: '140px',
        },
        {
          label: this.$t('Setting'),
          field: 'editSetting',
          sortable: false,
          width: '60px',
        },
        {
          label: this.$t('Last Time Login'),
          field: 'last_login',
          width: '200px',
        },
      ],
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 100,
      },
    }
  },
  computed: {
    breadcrumbs() {
      return store.getters['appBreadCrumb/getBreadcrumbs']
    },
  },
  // watch: {
  //   $route() {
  //     this.onLoadData()
  //   },
  // },
  mounted() {
    this.onLoadData()
  },
  methods: {
    statusColorBoolean,
    statusActiveBoolean,
    DatetimeFormat,

    async onLoadData() {
      const params = this.$refs.tableDataMember.getParams()
      this.$wait.start('table-loading-data')
      try {
        const mainPath = '/agent/sub-agent/accounts'
        const path = this.downlineId ? `${mainPath}/${this.downlineId}` : mainPath
        const { data } = await this.$http.get(path, {
          params,
        })
        this.rows = data?.data?.docs ?? []
        this.totalRecords = data?.data?.totalDocs ?? 0
      } finally {
        this.$wait.end('table-loading-data')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
